<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ id ? $t("label.Edit") : $t("label.Add") }} {{ $t("label.Coupon") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  v-model="detail.discount_name"
                                  class="form-control filled"
                                  rules="required:Offer Name"
                                  :validateOnInput="true"
                                />
                                <label for="fname">{{ $t("label.Offer Name") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="text"
                                  id="code"
                                  name="code"
                                  v-model="detail.discount_code"
                                  class="form-control filled"
                                  rules="required:code"
                                  :validateOnInput="true"
                                />
                                <label for="code">{{ $t("label.Coupon Code") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="code" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="text"
                                  id="amount"
                                  name="amount"
                                  v-model="detail.amount"
                                  class="form-control filled"
                                  rules="required:amount"
                                  :validateOnInput="true"
                                />
                                <label for="amount">{{ $t("label.Amount") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="amount" class="validation-msg" />
                            </div>

                            <div class="col-md-12">
                              <div class="col-4 p-0 mb-2">
                                <label class="pr-2">{{
                                  $t("label.Discount Applied")
                                }}</label>
                              </div>
                              <div class="d-flex flex-row pb-2">
                                <div class="col-4 custom-control custom-radio mb-2">
                                  <input
                                    type="radio"
                                    :id="'id_flat'"
                                    name="reason"
                                    class="custom-control-input"
                                    v-on:click="selectReason('FLAT')"
                                  />

                                  <label class="custom-control-label" :for="'id_flat'">
                                    {{ $t("label.Flat") }}</label
                                  >
                                </div>
                                <div class="col-4 custom-control custom-radio mb-2">
                                  <input
                                    type="radio"
                                    :id="'id_percent'"
                                    name="reason"
                                    class="custom-control-input ml-5"
                                    v-on:click="selectReason('PERCENTAGE')"
                                  />

                                  <label class="custom-control-label" :for="'id_percent'">
                                    {{ $t("label.Percentage") }}</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Datepicker
                                  v-model="detail.start_date"
                                  hideInputIcon
                                  :clearable="false"
                                  :enableTimePicker="false"
                                  format="yyyy/MM/dd"
                                  :placeholder="$t('label.Start Date')"
                                  :locale="lang"
                                  :cancelText="$t('label.Cancel')"
                                  :selectText="$t('label.Select')"
                                ></Datepicker>

                                <img src="/images/calendar.svg" width="20" class="icon" />
                              </div>
                              <ErrorMessage name="date" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Datepicker
                                  v-model="detail.end_date"
                                  hideInputIcon
                                  :clearable="false"
                                  :enableTimePicker="false"
                                  format="yyyy/MM/dd"
                                  :placeholder="$t('label.End Date')"
                                  :locale="lang"
                                  :cancelText="$t('label.Cancel')"
                                  :selectText="$t('label.Select')"
                                ></Datepicker>

                                <img src="/images/calendar.svg" width="20" class="icon" />
                              </div>
                              <ErrorMessage name="end_date" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group with-icon">
                                <Field
                                  type="text"
                                  id="use"
                                  name="use"
                                  v-model="detail.minimum_use"
                                  class="form-control filled"
                                  rules="required:minimum use"
                                  :validateOnInput="true"
                                />
                                <label for="use">{{ $t("label.Minimum Use") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="use" class="validation-msg" />
                            </div>
                          </div>
                        </div>
                        <div class="text-center mt-3">
                          <button
                            type="button"
                            class="btn btn-brand-1 mb-3 mr-2 px-5"
                            v-on:click="$router.go(-1)"
                          >
                            {{ $t("label.Cancel") }}
                          </button>
                          <button class="btn btn-brand-1 mb-3 px-5" id="save-button">
                            {{ $t("label.Save") }}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "EditAdmin",
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      file: undefined,
      detail: {
        dial_code: "+966",
        country_code: "sa",
        name: "",
        email: "",
        type: "",
      },
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      roleName: "",
      roleId: "",
      roleList: [],
      lang: "ar",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "ar";
    if (this.id) {
      this.getCouponDetail();
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    selectReason(type) {
      this.detail.type = type;
    },
    getCouponDetail() {
      this.$api
        .getAPI({
          _action: "discount-coupon/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },

    update() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "discount-coupon/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.push("/coupons");
          })
          .catch(() => {});
      } else {
        console.log("detail", this.detail);
        this.$api
          .postAPI({
            _action: "discount-coupon",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.push("/coupons");
          })
          .catch(() => {});
      }
    },
  },
};
</script>

