<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row p-3 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-arrow">
                <i class="bx bx-arrow-back"></i
              ></a>
              {{ $t("label.Transaction Detail") }}
            </h2>
          </div>
          <div class="col-md-6 text-right d-md-flex justify-content-end mb-3">
            <h4 class="font-medium">{{ $t("label.Transaction Id") }}: #{{ detail?.transaction?.id }}</h4>
          </div>
        </div>
        <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-6">
              <h4 class="mb-1 font-16">
                <i class="bx bxs-user mr-1"></i>
                {{ detail?.transaction?.users?.name }}
              </h4>
              <p>
                <i class="bx bxs-phone mr-1"></i>
                {{ detail?.transaction?.users?.phone_number }},<br />
                <span
                  ><i class="bx bxs-envelope mr-1"></i
                  >{{ detail?.transaction?.users?.email }}
                </span>
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-6">
              <p class="mb-1 text-secondary"><i class="bx bxs-map"></i> {{ $t("label.Location") }}</p>
              <p class="font-medium">
                {{ detail?.shipping?.address }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-6">
              <p class="mb-1 text-secondary">{{ $t("label.Date & Time") }}</p>
              <p class="font-medium mb-1">
                <i class="bx bxs-calendar text-secondary-2"></i> {{ detail.created_at }}
              </p>
              <p class="font-medium">
                <i class="bx bxs-time text-secondary-2"></i> {{ detail.created_at }}
              </p>
            </div>
          </div>

          <table
            class="table table-style-1 table-responsive mb-4 table table-no-responsive-md view-order"
          >
            <thead>
              <tr>
                <th scope="col">{{ $t("label.S_No") }}</th>
                <th scope="col">{{ $t("label.Image") }}</th>
                <th scope="col" class="serive-name">{{ $t("label.Product Name") }}</th>

                <th scope="col">{{ $t("label.Quantity") }}</th>
                <th scope="col" class="text-right">{{ $t("label.Amount") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in detail?.products" :key="index">
                <td>{{ index + 1 }}</td>

                <td>
                  <img
                    :src="
                      data?.product?.images?.image
                        ? data?.product?.images?.image
                        : '/images/placeholder.png'
                    "
                    width="50"
                    height="40"
                    class="object-cover"
                  />
                </td>
                <td>{{ data?.product.title }}</td>
                <td>{{ data?.quantity }}</td>
                <td class="text-right">SAR {{ data.amount }}</td>
              </tr>
              <tr>
                <td colspan="5" class="text-right">
                  <span class="mr-md-4 mr-3 font-16">{{ $t("label.Sub Total") }}</span>
                  <span class="font-bold font-16">SAR {{ detail.sub_total }}</span>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right border-0">
                  <span class="mr-md-4 mr-3 font-16">{{ $t("label.Discount") }}</span>
                  <span class="font-bold font-16">SAR {{ detail.discount }}</span>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right border-0">
                  <span class="mr-md-4 mr-3 font-16">{{
                    $t("label.Delivery Charges")
                  }}</span>
                  <span class="font-bold font-16"
                    >SAR {{ detail.shipping_charges }}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right border-0">
                  <span class="mr-md-4 mr-3 font-16">{{ $t("label.Total") }}</span>
                  <span class="font-bold font-16">SAR {{ detail.net_amount }} </span>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right border-0">
                  <span class="mr-md-4 mr-3 font-16">{{ $t("label.VAT") }} {{ $t("label.Include") }}</span>
                  <span class="font-bold font-16">SAR {{ detail.taxes }} </span>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row">
            <div class="col-md-12 text-center">
              <button
                type="button"
                class="btn btn-brand-1 cancel btn-h-40"
                style="min-width: 122px"
                v-on:click="$router.go(-1)"
              >
                <i class="bx bx-chevron-left ml-n2"></i>{{ $t("label.Back") }} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "EditSupplier",
  components: {
    // Form,
    // Field,
    // ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        dial_code: "+966",
        country_code: "sa",
        name: "",
        email: "",
      },
      category: {},
      subCategory: {},
      supplierDetails: {},
      productAttributes: [],
    };
  },
  mounted() {
    // alert("hello");
    if (this.id) {
      this.getProductDetail();
    }
  },
  methods: {
    getProductDetail() {
      this.$api
        .getAPI({
          _action: "transaction/" + this.id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
  },
};
</script>

