import {
  createWebHistory,
  createRouter
} from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import Setting from "@/views/MyProfile/setting.vue";
import manageWithdrawalRequest from "@/views/manageWithdrawalRequest/manage-withdrawal-request.vue";
import ManageAdmin from "@/views/Admin/manage-admin.vue";
import MyProfile from "@/views/MyProfile/MyProfile.vue";
import MainView from "@/views/main.vue";
import EditAdmin from "@/views/Admin/edit-admin.vue";
import AddAdmin from "@/views/Admin/edit-admin.vue";
import ResetPassword from "@/views/MyProfile/reset-password.vue";
import ProductOrderDetail from "@/views/orders/product/product-detail.vue";
import ServiceOrderDetail from "@/views/orders/service/service-detail.vue";
import ManageProductOrder from "@/views/orders/product/product-order.vue";
import ManageServiceOrder from "@/views/orders/service/service-order.vue";
import WalletTransactions from "@/views/WalletTransactions/manage-wallet.vue";
import ManageCategory from "@/views/ManageCategoriesAndSubCategories/manage-categories.vue";
import AddCategory from "@/views/ManageCategoriesAndSubCategories/add-Category.vue";
import EditCategory from "@/views/ManageCategoriesAndSubCategories/add-Category.vue";
import ManageSubCategory from "@/views/ManageCategoriesAndSubCategories/manage-sub_category.vue";
import AddSubCategory from "@/views/ManageCategoriesAndSubCategories/add-sub_category.vue";
import EditSubCategory from "@/views/ManageCategoriesAndSubCategories/add-sub_category.vue";
import ManageBanner from "@/views/Banners/manage-banners.vue";
import AddBanner from "@/views/Banners/add-banner.vue";
import DashBoard from "@/views/Dashboard/dashboard.vue";
import ManageApproval from "@/views/ManageApproval/manage-product-service.vue";
import ApprovalRequest from "@/views/ManageApproval/viewAcceptRejectDetails.vue";
import ManageCustomer from "@/views/ManageCustomer/manage-customer.vue";
import AddCustomer from "@/views/ManageCustomer/add-customer.vue";
import EditCustomer from "@/views/ManageCustomer/edit-customer.vue";
import ChangePassword from "@/views/ManageCustomer/change-password.vue";
import ManageSupplier from "@/views/ManageSupplier/manage-supplier.vue";
import SupplierDetail from "@/views/ManageSupplier/supplier-detail.vue";
import EditSupplier from "@/views/ManageSupplier/edit-supplier.vue";
import SupplierPassword from "@/views/ManageSupplier/supplier-password.vue";
import AddSupplier from "@/views/ManageSupplier/add-supplier.vue";
import ManageCoupon from "@/views/ManageCoupon/manage-coupon.vue";
import EditCoupon from "@/views/ManageCoupon/add-coupon.vue";
import AddCoupon from "@/views/ManageCoupon/add-coupon.vue";
import ManageContent from "@/views/ManageContent/manage-content.vue";
import AdminPassword from "@/views/Admin/admin-password.vue";
import OperatingArea from "@/views/ManageSupplier/operating-area.vue";
import ManageFAQs from "@/views/Manage FAQ's/manage faq's.vue";
import ManageNotification from "@/views/Notifications/notification.vue";
import ManageHelp from "@/views/Help/help.vue";
import SendNotification from "@/views/Notifications/send-notification.vue";
import ManageRole from "@/views/Manage Role/manage-role.vue";
import AddRole from "@/views/Manage Role/add-role.vue";
import AddFAQs from "@/views/Manage FAQ's/add-faq.vue";
import ViewHistory from "@/views/Notifications/history.vue";
import EditContent from "@/views/ManageContent/edit-content.vue";
import ReportList from "@/views/Reports/report-list.vue";
import ReportView from "@/views/Reports/report-view.vue";
import ManageProductService from "@/views/ManageServiceProduct/manage-service-product.vue";
import AddProduct from "@/views/ManageServiceProduct/add-product.vue";
import EditProduct from "@/views/ManageServiceProduct/add-product.vue";
import EditService from "@/views/ManageServiceProduct/add-service.vue";
import Transactions from "@/views/Transaction/transactions.vue";
import TransactionsDetails from "@/views/Transaction/transactionDetails.vue";
import AddSubOrder from "@/views/orders/service/addSubOrder.vue";

const routes = [{
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [{
        path: "/manage-admins",
        name: "Manage Admins",
        component: ManageAdmin,
      },
      {
        path: "/my-profile",
        name: "MyProfile",
        component: MyProfile,
      },
      {
        path: "/edit-admin/:id",
        name: "EditAdmin",
        component: EditAdmin,
      },
      {
        path: "/add-admin",
        name: "AddAdmin",
        component: AddAdmin,
      },
      {
        path: "/reset-password",
        name: "Reset Password",
        component: ResetPassword,
      },
      {
        path: "/settings",
        name: "Setting",
        component: Setting,
      },
      {
        path: "/manage-withdrawal-request",
        name: "manageWithdrawalRequest",
        component: manageWithdrawalRequest,
      },
      {
        path: "/manage-product-order",
        name: "Manage Product Order",
        component: ManageProductOrder,
      },
      {
        path: "/manage-service-order",
        name: "Manage Service Order",
        component: ManageServiceOrder,
      },
      {
        path: "/product-order-detail/:order_id",
        name: "Product Order Detail",
        component: ProductOrderDetail,
      },
      {
        path: "/service-order-detail/:order_id",
        name: "Service Order Detail",
        component: ServiceOrderDetail,
      },
      {
        path: "/add-suborder/:id",
        name: "Sub Order",
        component: AddSubOrder,
      },
      {
        path: "/wallet-transactions",
        name: "Wallet Transactions",
        component: WalletTransactions,
      },
      {
        path: "/manage-categories",
        name: "Manage Category",
        component: ManageCategory,
      },
      {
        path: "/add-category",
        name: "Add Category",
        component: AddCategory,
      },
      {
        path: "/edit-category/:id",
        name: "Edit Category",
        component: EditCategory,
      },
      {
        path: "/manage-sub_categories/:id",
        name: "Manage SubCategory",
        component: ManageSubCategory,
      },
      {
        path: "/add-sub_category",
        name: "Add SubCategory",
        component: AddSubCategory,
      },
      {
        path: "/edit-sub_category/:id",
        name: "Edit SubCategory",
        component: EditSubCategory,
      },
      {
        path: "/banners",
        name: "Manage Banner",
        component: ManageBanner,
      },
      {
        path: "/add-banner",
        name: "Add Banner",
        component: AddBanner,
      },
      {
        path: "/manage-customer",
        name: "ManageCustomer",
        component: ManageCustomer,
      },
      {
        path: "/add-customer",
        name: "Add Customer",
        component: AddCustomer,
      },
      {
        path: "/edit-customer/:id",
        name: "Edit Customer",
        component: EditCustomer,
      },
      {
        path: "/customer/change-password/:id",
        name: "Change Password",
        component: ChangePassword,
      },
      {
        path: "/manage-supplier",
        name: "ManageSupplier",
        component: ManageSupplier,
      },
      {
        path: "/supplier-detail/:id",
        name: "Supplier Detail",
        component: SupplierDetail,
      },
      {
        path: "/edit-supplier/:id",
        name: "Edit Supplier",
        component: EditSupplier,
      },
      {
        path: "/supplier/change-password/:id",
        name: "Supplier Password",
        component: SupplierPassword,
      },
      {
        path: "/add-supplier",
        name: "Add Supplier",
        component: AddSupplier,
      },
      {
        path: "/coupons",
        name: "Manage Coupon",
        component: ManageCoupon,
      },
      {
        path: "/add-coupon",
        name: "Add Coupon",
        component: AddCoupon,
      },
      {
        path: "/edit-coupon/:id",
        name: "Edit Coupon",
        component: EditCoupon,
      },
      {
        path: "/contents",
        name: "Manage Content",
        component: ManageContent,
      },
      {
        path: "/admin-password/:id",
        name: "Admin Password",
        component: AdminPassword,
      },
      {
        path: "/supplier/area",
        name: "Operating Area",
        component: OperatingArea,
      },
      {
        path: "/faq",
        name: "Manage FAQ's",
        component: ManageFAQs,
      },
      {
        path: "/add-faq",
        name: "Add FAQ's",
        component: AddFAQs,
      },
      {
        path: "/edit-faq/:id",
        name: "Edit FAQ's",
        component: AddFAQs,
      },
      {
        path: "/help",
        name: "Manage Help",
        component: ManageHelp,
      },
      {
        path: "/notification",
        name: "Manage Notification",
        component: ManageNotification,
      },
      {
        path: "/send-notification",
        name: "Send Notification",
        component: SendNotification,
      },
      {
        path: "/roles",
        name: "Manage Role",
        component: ManageRole,
      },
      {
        path: "/add-role",
        name: "Add Role",
        component: AddRole,
      },
      {
        path: "/edit-role/:id",
        name: "Edit Role",
        component: AddRole,
      },
      {
        path: "/history/:id",
        name: "View History",
        component: ViewHistory,
      },
      {
        path: "/edit-content/:id",
        name: "Edit Content",
        component: EditContent,
      },
      {
        path: "/reports",
        name: "Report List",
        component: ReportList,
      },
      {
        path: "/report-view/:type",
        name: "Report View",
        component: ReportView,
      },
      {
        path: "/manage-product-service",
        name: "Manage Product Service",
        component: ManageProductService,
      },
      {
        path: "/edit-product/:id",
        name: "Edit Product",
        component: EditProduct,
      },
      {
        path: "/edit-service/:id",
        name: "Edit Service",
        component: EditService,
      },
      {
        path: "/add-product",
        name: "Add Product",
        component: AddProduct,
      },
      {
        path: "/add-service",
        name: "Add Service",
        component: EditService,
      },
      {
        path: "/manage-product-service-approval",
        name: "Manage Approval",
        component: ManageApproval,
      },
      {
        path: "/view-product-service-approval/:id",
        name: "Approval Request",
        component: ApprovalRequest,
      },
      {
        path: "/dashboard",
        name: "Dash Board",
        component: DashBoard,
      },
      {
        path: "/transactions",
        name: "TranSaction",
        component: Transactions,
      },
      {
        path: "/view-transaction-details/:id",
        name: "Transaction Details",
        component: TransactionsDetails,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;