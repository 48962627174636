<template>
  <header class="header">
    <div class="row main-row">
      <div
        class="col-lg-6 col-md-10 col-6 align-self-center d-flex align-items-center pr-0"
      >
        <a class="mr-md-4 mr-2 pr-lg-2 text-dark font-22" id="hear_menu_toggle"
          ><i class="bx bx-menu-alt-left"></i
        ></a>
        <img
          style="cursor: pointer"
          v-on:click="$router.push('/dashboard')"
          src="/images/gardening_new_log.jpg"
          width="200"
          height="39"
          alt="Logo"
          class="img-fluid header-logo mr-3"
        />
      </div>
      <div class="col-lg-6 col-md-2 col-6 d-flex justify-content-end align-items-center">
        <div class="form-style lang-dd mr-3">
          <div
            class="selectParent mr-3"
            style="padding: 5px !important; text-align: center"
          >
            <select class="select-option" v-model="language" @change="saveLanguage()">
              <option value="en">EN</option>
              <option value="ar">AR</option>
            </select>
            <!-- EN -->
          </div>
        </div>
        <a href="" class="font-22 mr-3 text-dark"><i class="bx bx-bell"></i></a>
        <ul class="navbar-nav bg-light rounded-l">
          <li class="nav-item dropdown notification-dd cursor-pointer">
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark mx-2 py-md-2 py-1"
              href="user-profile.php"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                :src="detail.avatar ? detail.avatar : '/images/imageplace-holder.svg'"
                width="32"
                height="32"
                class="rounded-circle object-cover"
              />
              <span class="text-secondary font-14"
                >{{ detail.name }}<i class="bx bx-chevron-down align-middle"></i
              ></span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
              style="width: 245px"
            >
              <a class="dropdown-item cursor-pointer" href="/my-profile"
                ><i class="bx bx-user-circle font-20 align-text-top mr-1"></i>{{ $t("label.My Profile") }}</a
              >
              <!-- <a
                class="dropdown-item cursor-pointer"
                v-on:click="
                  $router.push('/company-profile/' + detail?.company.id)
                "
                ><i class="bx bxs-briefcase font-20 align-text-top mr-1"></i
                >Company Profile</a
              > -->
              <!-- <a class="dropdown-item cursor-pointer" href="/settings"
                ><i class="bx bxs-cog font-20 align-text-top mr-1"></i>
                Settings</a
              > -->
              <!-- <a
                class="dropdown-item cursor-pointer"
                v-on:click="$router.push('/terms-conditions/' +type1)"
                ><i class="bx bxs-file font-20 align-text-top mr-1"></i> Terms &
                Condition</a
              > -->

              <a class="dropdown-item cursor-pointer" href="/login"
                ><i class="bx bxs-log-out-circle font-20 align-text-top mr-1"></i>
                {{ $t("label.Logout") }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
import moment from "moment";
export default {
  name: "InnerHeader",
  data() {
    return {
      type1: "TERMS_AND_CONDITION",
      detail: {},
      token: localStorage.getItem("access_token"),
      currentTab: location.pathname.replace("/", ""),
      language: localStorage.getItem("language"),
    };
  },

  mounted() {
    if (!this.language) {
      this.language = "ar";
      localStorage.setItem("language", "ar");
      moment.locale(localStorage.getItem("language"));
    }
    moment.locale(localStorage.getItem("language"));
    this.meCall();
  },

  methods: {
    saveLanguage() {
      localStorage.setItem("language", this.language);
      this.$i18n.locale = this.lang;
      location.reload();
    },
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.detail = res.info;
          console.log("object", this.detail.company.supplier_id);
          this.$localStorage.setItem("supplier_id", this.detail.company.supplier_id);
          // this.detail.address = res.info.address;
          // var $this = this;
          // setTimeout(() => {
          //   // document.getElementById("title").value = $this.meetingData.title;
          //   document.getElementById("map").value = $this.detail.address;
          // }, 500);
        })
        .catch(() => {});
    },
    showDropdown() {
      var dropdownId = document.getElementById("dropdownMenuLink");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
    },
  },
};
</script>

