<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  Supplier Detail
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 130%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="col-lg-12 col-md-12 mx-auto mb-4 text-center">
                            <div class="mb-3">
                              <img
                                :src="
                                  detail.avatar && detail.avatar != null
                                    ? detail.avatar
                                    : '/images/profile.png'
                                "
                                class="rounded-circle border border-light"
                                style="border-width: 3px !important"
                                alt="image thumb"
                                id="preview"
                                width="130"
                                height="130"
                              />
                            </div>
                          </div>
                          <br /><br />
                          <div class="row">
                            <div class="col-lg-4 col-md-4 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">
                                Company Name :
                              </h6>
                              <h6 class="mx-3">{{ detail.name }}</h6>
                            </div>
                            <div class="col-lg-4 col-md-4 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">Email :</h6>
                              <h6 class="mx-3">{{ detail.email }}</h6>
                            </div>
                            <div class="col-lg-4 col-md-4 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">Phone :</h6>
                              <h6 class="mx-3">{{ detail.phone_number }}</h6>
                            </div>
                          </div>
                          <br /><br />
                          <div class="row">
                            <div class="col-lg-4 col-md-4 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">Address :</h6>
                              <h6 class="mx-3">{{ detail.address }}</h6>
                            </div>
                            <div class="col-lg-4 col-md-4 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">
                                {{ $t("label.Operating Area") }} :
                              </h6>
                              <h6
                                class="mx-3"
                                v-for="(data, index) in operating_area"
                                :key="index"
                              >
                                {{ data.area }}
                              </h6>
                              <br />
                            </div>
                            <div class="col-lg-4 col-md-4 d-flex flex-row">
                              <h6 style="font-weight: bolder !important">Status :</h6>
                              <h6 class="mx-3">{{ detail.status }}</h6>
                            </div>
                          </div>
                          <br /><br />
                          <div class="row" v-if="detail.status == 'PENDING'">
                            <div class="col-lg-10 col-md-10 mx-auto">
                              <div class="text-center mt-3">
                                <button
                                  type="button"
                                  class="btn btn-brand-1 mb-3 mr-2 px-5"
                                  v-on:click="status('ACCEPTED')"
                                  title="Accept"
                                >
                                  Accept
                                </button>
                                <button
                                  class="btn btn-brand-1 mb-3 px-5"
                                  id="save-button"
                                  v-on:click="status('REJECTED')"
                                  title="Reject"
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "EditSupplier",
  components: {
    // Form,
    // Field,
    // ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        dial_code: "+966",
        country_code: "sa",
        name: "",
        email: "",
      },
      operating_area: [],
    };
  },
  mounted() {
    // alert("hello");
    if (this.id) {
      this.getSupplierDetail();
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    status(status) {
      var obj = {};
      obj.supplier_status = status;
      this.$api
        .postAPI({
          _action: "supplier/change-status/" + this.detail.id,
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.push("/manage-supplier");
        })
        .catch(() => {});
    },
    getSupplierDetail() {
      this.$api
        .getAPI({
          _action: "supplier/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          this.operating_area = res.info.operating_areas;
        })
        .catch(() => {});
    },
  },
};
</script>

