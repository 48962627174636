<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 100%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Manage Customers") }}
                  </h2>
                </div>
              </div>
              <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                <div class="row mb-3">
                  <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                    <div class="input-group page-cont-search border col-md-6 p-0">
                      <span class="input-group-text"><i class="bx bx-search"></i></span>
                      <input
                        type="text"
                        class="form-control"
                        style="height: 55px"
                        name=""
                        v-on:keyup.enter="getCustomerList(1)"
                        :placeholder="$t('label.search here')"
                        v-model="searchObj.keyword"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-7 text-right d-md-flex justify-content-end mb-3"
                  >
                    <button
                      type="button"
                      class="btn btn-brand-1 btn-sm"
                      v-on:click="$router.push('/add-customer')"
                      v-if="access.can_create"
                    >
                      <i class="bx bx-plus"></i> {{ $t("label.Add") }}
                    </button>
                  </div>
                </div>
                <table class="table table-style-1 table-responsive" id="sorting_tbl">
                  <thead>
                    <tr>
                      <th class="sorting cursor-pointer">{{ $t("label.S_No") }}</th>
                      <th
                        class="sorting cursor-pointer"
                        id="id-name"
                        v-on:click="sorting('name', 'id-name')"
                      >
                        {{ $t("label.Customer Name") }}
                      </th>
                      <th
                        class="sorting cursor-pointer"
                        id="id-email"
                        v-on:click="sorting('email', 'id-email')"
                      >
                        {{ $t("label.Email") }}
                      </th>
                      <th
                        class="sorting cursor-pointer"
                        id="id-phone"
                        v-on:click="sorting('phone_number', 'id-phone')"
                      >
                        {{ $t("label.Mobile Number") }}
                      </th>
                      <th
                        class="sorting cursor-pointer"
                        id="id-wallet_balance"
                        v-on:click="sorting('wallet_balance', 'id-wallet_balance')"
                      >
                        {{ $t("label.Wallet Balance") }}
                      </th>

                      <th>{{ $t("label.Action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in customerList" :key="index">
                      <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                      <td>{{ data.name }}</td>
                      <td>{{ data.email }}</td>
                      <td>{{ data.phone_number }}</td>
                      <td>{{ data.wallet_balance }}</td>

                      <td>
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                          v-on:click="router(data.id)"
                          v-if="access.can_update"
                          title="Edit"
                        >
                          <i class="bx bxs-pencil"></i>
                        </button>
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                          v-on:click="showDeletePopup(data.id)"
                          v-if="access.can_delete"
                          title="Delete"
                        >
                          <i class="bx bxs-trash"></i>
                        </button>

                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                          v-on:click="withdrawal('Withdrawal', data.id)"
                          title="Withdrawal"
                        >
                          <!-- <a href="#">Withdrawal<i class="bx bx-money-withdraw"></i></a> -->
                          <i class="bx bx-money-withdraw"></i>
                        </button>
                        <button
                          class="btn btn-action mr-2 mb-md-0 mb-2"
                          v-on:click="withdrawal('Deposit', data.id)"
                          title="Deposit"
                        >
                          <i class="bx bx-wallet"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <ErrorComponent @retry="getCustomerList(1)" ref="errorComponent" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 mt-3 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
  <TextModal ref="textModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" v-if="customer_type == 'Withdrawal'">
            {{ $t("label.Withdrawal") }}
          </h5>
          <h5 class="modal-title" v-if="customer_type == 'Deposit'">
            {{ $t("label.Deposit") }}
          </h5>
        </div>

        <Form @submit="updateOrderStatus()">
          <div class="modal-body">
            <div class="col-lg-12 col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="">{{ $t("label.Amount") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="number"
                          id="amount"
                          rules="required:amount"
                          name="amount"
                          v-model="amount"
                          class="form-control filled"
                          min="0"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="amount" class="validation-msg" />
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="">{{ $t("label.Message") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="message"
                          name="message"
                          v-model="message"
                          rules="required:message"
                          class="form-control filled"
                          :validateOnInput="true"
                        >
                          <textarea
                            type="text-area"
                            class="form-control"
                            v-bind="field"
                            v-model="message"
                          />
                        </Field>
                      </div>
                      <ErrorMessage name="message" class="validation-msg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn btn-success"
              id="save-button"
              style="width: 18%; border-radius: 5px"
            >
              {{ $t("label.Save") }}
            </button>
            <button
              type="button"
              class="fil-btn btn-danger"
              id="cancel-button"
              style="width: 18%; border-radius: 5px"
              v-on:click="$refs.textModal.closeModal()"
            >
              {{ $t("label.Cancel") }}
            </button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "../../components/TextModal.vue";
export default {
  name: "ManageCustomer",

  components: {
    DeleteModal,
    ErrorComponent,
    Pagination,
    Field,
    ErrorMessage,
    TextModal,
    Form,
  },
  data() {
    return {
      customerList: [],
      // products: {},
      searchObj: {
        keyword: "",
        offset: 0,
      },
      currentPage: 1,
      loader: true,
      itemsPerPage: 10,
      access: { can_create: 1, can_update: 1, can_read: 1, can_delete: 1 },
      token: localStorage.getItem("access_token"),
      customer_id: "",
      customer_type: "",
      amount: "",
      message: "",
    };
  },
  mounted() {
    this.getCustomerList(1);
  },
  methods: {
    pageChange(page) {
      this.getCustomerList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getCustomerList(1);
    },
    retry() {
      this.getCustomerList(1);
    },
    getCustomerList(page, sortBy, orderBy) {
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.searchObj.sort_order = orderBy;
      }
      if (!page) {
        page = 1;
      }
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      // this.searchObj.row_per_page = this.itemsPerPage;

      this.customerList = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "customers",
          _body: this.searchObj,
        })
        .then((res) => {
          this.customerList = res.list;
          this.access = res.manage_customers;
          this.searchObj.offset = res.next_offset;

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
            if (res.count != null && res.count > 0 && this.access.can_read) {
              this.$refs.listPagination.setTotalCount(res.count);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }

          if (this.$refs.errorComponent) {
            if (this.access.can_read == 0) {
              this.$refs.errorComponent.setData(
                res,
                this.$t("label.Sorry, you don't have permission")
              );
              this.$refs.errorComponent.updateLoader(false);
              return;
            }
            this.$refs.errorComponent.setData(
              res,
              this.$t("label.Customer Not Available")
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    router(id) {
      this.$router.push("/edit-customer/" + id);
    },
    withdrawal(type, id) {
      this.customer_id = id;
      this.customer_type = type;
      this.amount = "";
      this.message = "";
      this.$refs.textModal.showModal();
    },
    updateOrderStatus() {
      var url = "";
      if (this.customer_type == "Withdrawal") {
        url = "customer/" + this.customer_id + "/wallet/withdraw";
      } else {
        url = "customer/" + this.customer_id + "/wallet/deposit";
      }
      this.$api
        .postAPI({
          _action: url,
          _body: { amount: this.amount, message: this.message },
        })
        .then((res) => {
          if (res) {
            this.customer_id = "";
            this.customer_type = "";
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.textModal.closeModal();
            this.getCustomerList(0);
          }
        })
        .catch(() => {});
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "customer/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.getCustomerList(1);
        })
        .catch(() => {});
    },

    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getCustomerList(1, obj.order_by, obj.sort_by);
    },
  },
};
</script>

