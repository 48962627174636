<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row p-3 align-items-center">
                <div class="col-md-6 col-5">
                  <h2 class="page-title mb-md-0">
                    <a
                      href="javascript:void(0)"
                      v-on:click="$router.go(-1)"
                      class="back-arrow"
                    >
                      <i class="bx bx-arrow-back"></i
                    ></a>
                    {{ $t("label.Edit Supplier") }}
                  </h2>
                </div>
              </div>

              <ul
                class="row col-12 nav nav-pills mb-3 tab-style-1"
                id="pills-tab"
                role="tablist"
              >
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pending_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'BASIC' }"
                    v-on:click="changeTab('BASIC')"
                    style="cursor: pointer"
                    >{{ $t("label.Basic Info") }}</a
                  >
                </li>
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="accepted_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'AREA' }"
                    v-on:click="changeTab('AREA')"
                    style="cursor: pointer"
                    >{{ $t("label.Operating Area") }}</a
                  >
                </li>
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="rejected_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'FEES' }"
                    v-on:click="changeTab('FEES')"
                    style="cursor: pointer"
                    >{{ $t("label.Fees") }}</a
                  >
                </li>
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="delivered_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'EARNINGS' }"
                    v-on:click="changeTab('EARNINGS')"
                    style="cursor: pointer"
                    >{{ $t("label.Earnings") }}</a
                  >
                </li>
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="cancelleds_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'PRODUCTS' }"
                    v-on:click="changeTab('PRODUCTS')"
                    style="cursor: pointer"
                    >{{ $t("label.Products") }}</a
                  >
                </li>
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="cancelled_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'PRODUCT' }"
                    v-on:click="changeTab('PRODUCT')"
                    style="cursor: pointer"
                    >{{ $t("label.Product Orders") }}</a
                  >
                </li>
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="cancelledss_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'SERVICES' }"
                    v-on:click="changeTab('SERVICES')"
                    style="cursor: pointer"
                    >{{ $t("label.Services") }}</a
                  >
                </li>
                <li class="text-center nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="cancelled_orders-tab"
                    data-toggle="pill"
                    role="tab"
                    :class="{ active: currentTab == 'SERVICE' }"
                    v-on:click="changeTab('SERVICE')"
                    style="cursor: pointer"
                    >{{ $t("label.Service Orders") }}</a
                  >
                </li>
              </ul>
              <div
                class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
                style="width: 100%"
                v-if="this.currentTab == 'BASIC'"
              >
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade active show"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div class="form-style floating-form">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-5 mb-4 text-center">
                          <div class="mb-3">
                            <img
                              :src="
                                supplierDetail.avatar && supplierDetail.avatar != null
                                  ? supplierDetail.avatar
                                  : '/images/profile.png'
                              "
                              class="rounded-circle border border-light"
                              style="border-width: 3px !important"
                              alt="image thumb"
                              id="preview"
                              width="130"
                              height="130"
                            />
                          </div>
                          <button
                            type="button"
                            class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                            for="pickFile"
                          >
                            {{ $t("label.Change photo") }}
                            <input
                              type="file"
                              class="btn upload-img"
                              id="pickFile"
                              @change="selectedFile($event)"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <label for="fname">{{ $t("label.Company Name") }}</label>
                              <span class="text-danger">*</span>
                              <div class="form-group">
                                <Field
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  v-model="supplierDetail.name"
                                  class="form-control"
                                  rules="required:Company Name"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <label for="email">{{ $t("label.Email") }}</label>
                              <span class="text-danger">*</span>
                              <div class="form-group">
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="supplierDetail.email"
                                  class="form-control"
                                  rules="required:email"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="phone">{{ $t("label.Phone") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="supplierDetail.phone_number"
                                  class="form-control filled"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                  ><PhoneCode
                                    id="mobile"
                                    placeholder="Mobile Number"
                                    v-model:countryCode="supplierDetail.country_code"
                                    v-model="supplierDetail.phone_number"
                                    v-model:dialCode="supplierDetail.dial_code"
                                    v-bind="field"
                                  />
                                </Field>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="com_registration_no"
                                  name="com_registration_no"
                                  v-model="supplierDetail.com_registration_no"
                                  class="form-control filled"
                                  rules="required:Company Registration Number"
                                  :validateOnInput="true"
                                />
                                <label for="com_registration_no">{{
                                  $t("label.Company Registration Number")
                                }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage
                                name="com_registration_no"
                                class="validation-msg"
                              />
                            </div>

                            <div class="col-md-12 d-flex flex-row">
                              <div class="floating-form-group with-icon">
                                <vue-google-autocomplete
                                  ref="address1"
                                  id="address1"
                                  class="form-control filled"
                                  :placeholder="$t('label.Type Here')"
                                  v-model="address"
                                  v-on:placechanged="getWorkAddressData"
                                  types=""
                                >
                                </vue-google-autocomplete>
                                <label for="city">{{ $t("label.Work Address") }}</label>
                                <i class="bx bx-map icon"></i>
                                <span class="text-danger">*</span>
                              </div>
                              <a
                                :href="
                                  'http://maps.google.com/?q=' + supplierDetail.address
                                "
                                class=""
                                target="_blank"
                              >
                                <button
                                  class="btn"
                                  style="
                                    padding-top: 11px;
                                    border-radius: 0px 5px 5px 0px !important;
                                  "
                                >
                                  {{ $t("label.View") }}
                                </button>
                              </a>
                              <ErrorMessage name="city" class="validation-msg" />
                            </div>
                          </div>
                          <div class="d-flex flex-row">
                            <div class="col-lg-6 col-md-6"></div>
                            <div class="text-right col-lg-6 col-md-6">
                              <a
                                class="cursor-pointer btn"
                                v-on:click="
                                  $router.push(
                                    '/supplier/change-password/' + supplierDetail.id
                                  )
                                "
                                >{{ $t("label.Change Password") }}</a
                              >
                            </div>
                          </div>
                          <!-- <div class="row d-flex flex-row">
                            <div class="col-lg-8"></div>
                          </div> -->

                          <div class="text-center mt-3">
                            <button
                              id="submit-button"
                              class="btn btn-brand-1 mb-3 px-5 mr-2"
                              v-on:click="update()"
                            >
                              {{ $t("label.Save") }}
                            </button>
                            <button
                              type="button"
                              id="cancel-button"
                              class="btn btn-brand-1 mb-3 px-5"
                              v-on:click="$router.go(-1)"
                            >
                              {{ $t("label.Cancel") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
                style="width: 100%"
                v-if="this.currentTab == 'FEES'"
              >
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade active show"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div class="form-style floating-form">
                      <div class="row justify-content-center mb-3">
                        <Form @submit="updateSupplierSetting()">
                          <div class="col-md-10">
                            <div class="row">
                              <div class="col-md-8">
                                <label for="productfees">{{
                                  $t("label.Admin Product Fees")
                                }}</label>
                                <span class="text-danger">*</span>
                                <div class="form-group">
                                  <Field
                                    type="text"
                                    id="productfees"
                                    name="productfees"
                                    class="form-control"
                                    rules="required:product fees|numeric|max_value:100|min_value:0"
                                    v-model="fees.product_commission"
                                    :validateOnInput="true"
                                    v-if="fees.product_commission_type == 'PERCENT'"
                                  />
                                  <Field
                                    type="text"
                                    id="productfees"
                                    name="productfees"
                                    class="form-control"
                                    rules="required:product fees|numeric"
                                    v-model="fees.product_commission"
                                    :validateOnInput="true"
                                    v-else
                                  />
                                </div>
                                <ErrorMessage name="productfees" class="validation-msg" />
                              </div>

                              <div class="col-lg-4" style="margin-top: 5px">
                                <label for="role">
                                  <!-- {{
                                  $t("label.Product Commission Type")
                                }} -->
                                </label>
                                <div class="form-group">
                                  <select
                                    name="product_commission_type"
                                    rules="required:product commission type"
                                    class="form-control py-2"
                                    id=""
                                    v-model="fees.product_commission_type"
                                  >
                                    <!-- <option value="">
                                      {{ $t("label.Select Product Commission Type") }}
                                    </option> -->
                                    <option value="FIXED">{{ $t("label.Fixed") }}</option>
                                    <option value="PERCENT">
                                      {{ $t("label.Percentage") }}
                                    </option>
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="product_commission_type"
                                  class="validation-msg mt-3"
                                />
                              </div>
                              <div class="col-md-8">
                                <label for="servicefees">{{
                                  $t("label.Admin Service Fees")
                                }}</label>
                                <span class="text-danger">*</span>
                                <div class="form-group">
                                  <Field
                                    type="text"
                                    id="servicefees"
                                    name="servicefees"
                                    class="form-control"
                                    rules="required:service fees|numeric|max_value:100|min_value:0"
                                    v-model="fees.service_commission"
                                    :validateOnInput="true"
                                    v-if="fees.service_commission_type == 'PERCENT'"
                                  />
                                  <Field
                                    type="text"
                                    id="servicefees"
                                    name="servicefees"
                                    class="form-control"
                                    rules="required:service fees|numeric"
                                    v-model="fees.service_commission"
                                    :validateOnInput="true"
                                    v-else
                                  />
                                </div>
                                <ErrorMessage name="servicefees" class="validation-msg" />
                              </div>

                              <div class="col-lg-4" style="margin-top: 5px">
                                <label for="role">
                                  <!-- {{
                                  $t("label.Select Service Commission Type")
                                }} -->
                                </label>
                                <!-- <span class="text-danger">*</span> -->
                                <div class="form-group">
                                  <select
                                    name="service_commission_type"
                                    rules="required:service commission type"
                                    class="form-control py-2"
                                    id=""
                                    v-model="fees.service_commission_type"
                                  >
                                    <!-- <option value="">
                                      {{ $t("label.Select Service Commission Type") }}
                                    </option> -->
                                    <option value="FIXED">{{ $t("label.Fixed") }}</option>
                                    <option value="PERCENT">
                                      {{ $t("label.Percentage") }}
                                    </option>
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="service_commission_type"
                                  class="validation-msg mt-3"
                                />
                              </div>
                              <div class="col-md-12">
                                <label for="servicefees">{{
                                  $t("label.Product Delivery Charges(SAR)")
                                }}</label>
                                <span class="text-danger">*</span>
                                <div class="form-group">
                                  <Field
                                    type="text"
                                    id="shipping_charges"
                                    name="shipping_charges"
                                    class="form-control"
                                    rules="required:product dlivery charges|numeric"
                                    v-model="fees.shipping_charges"
                                    :validateOnInput="true"
                                  />
                                </div>
                                <ErrorMessage
                                  name="shipping_charges"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="font-14 d-block"
                                    >{{ $t("label.Has Minimum Order For Free Shipping") }}
                                  </label>
                                  <div class="custom-control custom-switch d-inline mr-3">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="shwitch_1"
                                      true-value="Y"
                                      false-value="N"
                                      v-model="fees.checkHasMinimumShipping"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="shwitch_1"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="fees.checkHasMinimumShipping == 'Y'"
                              >
                                <label for="address">{{
                                  $t("label.Minimum Order For Free Shipping")
                                }}</label>
                                <span class="text-danger">*</span>
                                <div class="form-group">
                                  <Field
                                    type="text"
                                    id="Shipping"
                                    name="Shipping"
                                    class="form-control"
                                    rules="required:minimum order"
                                    v-model="fees.min_order_value_for_free_shipping"
                                    :validateOnInput="true"
                                  />
                                </div>
                                <ErrorMessage name="Shipping" class="validation-msg" />
                              </div>
                            </div>
                            <div class="text-center mt-3">
                              <button
                                id="submit-button"
                                class="btn btn-brand-1 mb-3 px-5 mr-2"
                                type="submit"
                              >
                                {{ $t("label.Save") }}
                              </button>
                              <button
                                type="button"
                                id="cancel-button"
                                class="btn btn-brand-1 mb-3 px-5"
                                v-on:click="$router.go(-1)"
                              >
                                {{ $t("label.Cancel") }}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" v-if="currentTab == 'AREA'" id="operating_area">
                <div class="form-style floating-form" v-if="addMore == '1'">
                  <div class="row justify-content-center mb-3">
                    <div class="col-md-10">
                      <div class="col-lg-12 col-md-5 mb-4 text-center">
                        <div class="col-md-12 d-flex flex-row">
                          <div class="floating-form-group with-icon">
                            <vue-google-autocomplete
                              ref="address2"
                              name="1234"
                              id="address2"
                              class="form-control filled"
                              :placeholder="$t('label.Type Here')"
                              v-model="address1"
                              v-on:placechanged="getOperatingWorkAddressData"
                              types=""
                            >
                            </vue-google-autocomplete>

                            <label for="operating_area">{{
                              $t("label.Operating Area")
                            }}</label>
                            <i class="bx bx-map icon"></i>
                            <span class="text-danger">*</span>
                          </div>

                          <button
                            v-on:click="addToOperatingAreaList()"
                            class="btn"
                            style="
                              padding-top: 11px;
                              border-radius: 0px 5px 5px 0px !important;
                            "
                          >
                            {{ $t("label.Add") }}
                          </button>
                          <ErrorMessage name="operating_area" class="validation-msg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mx-3" v-show="operating_area.length > 0">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 d-flex justify-content-between">
                      <h5 class="">{{ $t("label.Operating Area") }}:</h5>
                      <a
                        v-on:click="showAddMore()"
                        class="text-right"
                        style="cursor: pointer"
                        v-if="addMore == '0'"
                      >
                        {{ $t("label.Add More") }}
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <br />

                    <table class="table table-style-1 table-responsive" id="sorting_tbl">
                      <thead>
                        <tr>
                          <th>{{ $t("label.S_No") }}</th>
                          <th>{{ $t("label.Work Address") }}</th>
                          <th>{{ $t("label.Action") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in operating_area" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ data?.area }}</td>
                          <td>
                            <button
                              class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                              v-on:click="showAddressPopup(index)"
                              title="Delete"
                            >
                              <i class="bx bxs-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="tab-content bg-white rounded p-3"
                id="pills-tabContent"
                v-if="currentTab == 'EARNINGS'"
              >
                <div
                  class="tab-pane fade show active"
                  id="pending_orders"
                  role="tabpanel"
                  aria-labelledby="pending_orders-tab"
                >
                  <div class="row mb-3">
                    <div class="col-md-3 d-md-flex mb-md-0 mb-2">
                      <div class="input-group page-cont-search border">
                        <span class="input-group-text"><i class="bx bx-search"></i></span>
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          v-on:keyup.enter="getEarnings(0)"
                          :placeholder="$t('label.search here')"
                          v-model="searchObj.keyword"
                        />
                      </div>
                    </div>
                    <div class="col-md-3 d-md-flex mb-md-0 mb-2">
                      <div class="input-group flex-nowrap">
                        <button class="btn-success" style="width: 200px">
                          {{ $t("label.Your Earning") }} {{ earnings.total_saving }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <table class="table table-style-1 table-responsive" id="sorting_tbl">
                    <thead>
                      <tr>
                        <th>{{ $t("label.S_No") }}</th>
                        <th>{{ $t("label.Order ID") }}</th>
                        <th>{{ $t("label.Date & Time") }}</th>
                        <th>{{ $t("label.Total Order Amount") }}</th>
                        <th>{{ $t("label.Earning Amount") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in earnings" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ $t("label.OrderId") }} {{ data.withdrawal_req_no }}</td>
                        <td>
                          {{ data.created_at }}
                        </td>
                        <td>{{ data.total_amount }}</td>
                        <td>{{ data.earnings }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="row mb-4 justify-content-center"
                v-if="this.currentTab == 'PRODUCTS'"
              >
                <div class="col-lg-12">
                  <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pending_orders"
                      role="tabpanel"
                      aria-labelledby="pending_orders-tab"
                    >
                      <div class="row mb-3">
                        <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                          <div class="input-group page-cont-search border col-md-6">
                            <span class="input-group-text"
                              ><i class="bx bx-search"></i
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              name=""
                              v-on:keyup.enter="getOrderList(1)"
                              :placeholder="$t('label.search here')"
                              v-model="searchObjs.keyword"
                            />
                          </div>
                        </div>
                      </div>
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th class="sorting cursor-pointer">
                              {{ $t("label.Image") }}
                            </th>
                            <th
                              class="sorting cursor-pointer"
                              id="id-title"
                              v-on:click="sorting('title', 'id-title')"
                            >
                              {{ $t("label.Product Name") }}
                            </th>
                            <th
                              class="sorting cursor-pointer"
                              id="id-category"
                              v-on:click="sorting('title', 'id-category')"
                            >
                              {{ $t("label.Category") }}
                            </th>
                            <th
                              class="sorting cursor-pointer"
                              id="id-sub-category"
                              v-on:click="sorting('id', 'id-sub-category')"
                            >
                              {{ $t("label.Sub-Category") }}
                            </th>
                            <th>{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in productList" :key="index">
                            <td>
                              <img
                                :src="
                                  data?.category?.image
                                    ? data.category.image
                                    : 'images/placeholder-image.jpg'
                                "
                                width="50"
                                height="40"
                                class="rounded"
                                alt=""
                              />
                            </td>
                            <td>
                              <a
                                style="cursor: pointer"
                                v-on:click="$router.push('/edit-product/' + data.id)"
                                class="text-primary"
                                >{{ data.title }}</a
                              >
                            </td>
                            <td>{{ data?.category.title }}</td>
                            <td>{{ data.sub_category.title }}</td>
                            <td>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                                v-if="data.status != 'REJECTED'"
                                v-on:click="$router.push('/edit-product/' + data.id)"
                              >
                                <i class="bx bxs-pencil"></i>
                              </button>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                v-on:click="
                                  showDeletePopup(
                                    data.supplier_product_id,
                                    data.supplier_id
                                  )
                                "
                              >
                                <i class="bx bxs-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row mb-4 justify-content-center"
                v-if="this.currentTab == 'PRODUCT'"
              >
                <div class="col-lg-12">
                  <ul
                    class="row col-12 nav nav-pills mb-3 tab-style-1"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item text-center" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'PENDING' }"
                        v-on:click="changeTab2('PENDING')"
                        style="cursor: pointer"
                        >{{ $t("label.Pending Orders") }}</a
                      >
                    </li>
                    <li class="nav-item text-center" role="presentation">
                      <a
                        class="nav-link"
                        id="accepted_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'ACCEPTED' }"
                        v-on:click="changeTab2('ACCEPTED')"
                        style="cursor: pointer"
                        >{{ $t("label.Accepted Orders") }}</a
                      >
                    </li>
                    <li class="nav-item text-center" role="presentation">
                      <a
                        class="nav-link"
                        id="rejected_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'REJECTED' }"
                        v-on:click="changeTab2('REJECTED')"
                        style="cursor: pointer"
                        >{{ $t("label.Rejected Orders") }}</a
                      >
                    </li>
                    <li class="nav-item text-center" role="presentation">
                      <a
                        class="nav-link"
                        id="delivered_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'COMPLETED' }"
                        v-on:click="changeTab2('COMPLETED')"
                        style="cursor: pointer"
                        >{{ $t("label.Delivered Orders") }}</a
                      >
                    </li>
                    <li class="nav-item text-center" role="presentation">
                      <a
                        class="nav-link"
                        id="cancelled_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab2 == 'CANCELLED' }"
                        v-on:click="changeTab2('CANCELLED')"
                        style="cursor: pointer"
                        >{{ $t("label.Cancelled Orders") }}</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pending_orders"
                      role="tabpanel"
                      aria-labelledby="pending_orders-tab"
                    >
                      <div class="row mb-3">
                        <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                          <div class="input-group page-cont-search border col-md-6">
                            <span class="input-group-text"
                              ><i class="bx bx-search"></i
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              name=""
                              v-on:keyup.enter="getOrderList(1)"
                              :placeholder="$t('label.search here')"
                              v-model="searchObjs.keyword"
                            />
                          </div>
                        </div>
                        <!-- <div class="col-md-3 mb-md-0 mb-2">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> From</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> To</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div> -->
                      </div>
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ $t("label.Sr_No") }}</th>
                            <th>{{ $t("label.Order ID") }}</th>
                            <th>{{ $t("label.Date & Time") }}</th>
                            <th>{{ $t("label.Preferred Date") }}</th>
                            <th>{{ $t("label.Customer Name") }}</th>

                            <th>{{ $t("label.Mobile Number") }}</th>
                            <th>{{ $t("label.Email") }}</th>
                            <th>{{ $t("label.Location") }}</th>
                            <th>{{ $t("label.Price") }}</th>
                            <th>{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in OrderList" :key="index">
                            <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                            <td>{{ data.shipping.order_id }}</td>
                            <td>{{ data.created_at }}</td>
                            <td>
                              <div>{{ data.preferred_date }}</div>
                          <span v-if="data.preferred_time == '06_AM_TO_10_AM'">{{
                            $t("label.06 AM TO 10 AM")
                          }}</span>
                          <span v-if="data.preferred_time == '10_AM_TO_02_PM'">{{
                            $t("label.10 AM TO 02 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '02_PM_TO_06_PM'">{{
                            $t("label.02 PM TO 06 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '06_PM_TO_10_PM'">{{
                            $t("label.06 PM TO 10 PM")
                          }}</span>
                            </td>
                            <td>{{ data.name }}</td>

                            <td>{{ data.phone_number }}</td>
                            <td>{{ data.email }}</td>
                            <td>{{ data.shipping.address }}</td>
                            <td>{{ data.net_amount }}</td>
                            <td>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                                v-on:click="
                                  $router.push(
                                    '/product-order-detail/' + data.shipping.order_id
                                  )
                                "
                              >
                                <i class="bx bx-show"></i>
                              </button>
                              <button
                                v-show="currentTab2 == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                                v-on:click="showModal(data.shipping.order_id, 'ACCEPTED')"
                              >
                                <i class="bx bxs-check-circle"></i>
                              </button>
                              <button
                                v-show="currentTab2 == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                v-on:click="showModal(data.shipping.order_id, 'REJECTED')"
                              >
                                <i class="bx bxs-x-circle"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row mb-4 justify-content-center"
                v-if="this.currentTab == 'SERVICES'"
              >
                <div class="col-lg-12">
                  <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pending_orders"
                      role="tabpanel"
                      aria-labelledby="pending_orders-tab"
                    >
                      <div class="row mb-3">
                        <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                          <div class="input-group page-cont-search border col-md-6">
                            <span class="input-group-text"
                              ><i class="bx bx-search"></i
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              name=""
                              v-on:keyup.enter="getOrderList(1)"
                              :placeholder="$t('label.search here')"
                              v-model="searchObjs.keyword"
                            />
                          </div>
                        </div>
                      </div>
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th class="sorting cursor-pointer">
                              {{ $t("label.Image") }}
                            </th>
                            <th
                              class="sorting cursor-pointer"
                              id="id-title"
                              v-on:click="sorting('title', 'id-title')"
                            >
                              {{ $t("label.Service Name") }}
                            </th>
                            <th
                              class="sorting cursor-pointer"
                              id="id-category"
                              v-on:click="sorting('title', 'id-category')"
                            >
                              {{ $t("label.Category") }}
                            </th>

                            <th>{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in serviceList" :key="index">
                            <td>
                              <img
                                :src="
                                  data?.category?.image
                                    ? data.category.image
                                    : 'images/placeholder-image.jpg'
                                "
                                width="50"
                                height="40"
                                class="rounded"
                                alt=""
                              />
                            </td>
                            <td>
                              <a
                                style="cursor: pointer"
                                v-on:click="$router.push('/edit-service/' + data.id)"
                                class="text-primary"
                                >{{ data.title }}</a
                              >
                            </td>
                            <td>{{ data?.category.title }}</td>
                            <td>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                                v-if="data.status != 'REJECTED'"
                                v-on:click="$router.push('/edit-service/' + data.id)"
                                title="Edit"
                              >
                                <i class="bx bxs-pencil"></i>
                              </button>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                v-on:click="showDeletePopup(data.id, null)"
                                title="Delete"
                              >
                                <i class="bx bxs-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row mb-4 justify-content-center"
                v-if="this.currentTab == 'SERVICE'"
              >
                <div class="col-lg-12">
                  <ul
                    class="nav nav-pills mb-3 tab-style-1"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'NEW_VISIT' }"
                        v-on:click="changeTab3('NEW_VISIT')"
                        style="cursor: pointer"
                        >{{ $t("label.New Visit") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'OUTGOING' }"
                        v-on:click="changeTab3('OUTGOING')"
                        style="cursor: pointer"
                        >{{ $t("label.Outgoing") }}</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'VISIT_COMPLETE' }"
                        v-on:click="changeTab3('VISIT_COMPLETE')"
                        style="cursor: pointer"
                      >
                        {{ $t("label.Visit Complete") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'PENDING_NEW_WORK' }"
                        v-on:click="changeTab3('PENDING_NEW_WORK')"
                        style="cursor: pointer"
                        >{{ $t("label.Pending New Work") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'NEW_WORK_UNDER_PROGRESS' }"
                        v-on:click="changeTab3('NEW_WORK_UNDER_PROGRESS')"
                        style="cursor: pointer"
                        >{{ $t("label.New Work Under Progress") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'NEW_WORK_COMPLETE' }"
                        v-on:click="changeTab3('NEW_WORK_COMPLETE')"
                        style="cursor: pointer"
                        >{{ $t("label.New Work Complete") }}
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pending_orders-tab"
                        data-toggle="pill"
                        role="tab"
                        :class="{ active: currentTab3 == 'REJECTED' }"
                        v-on:click="changeTab3('REJECTED')"
                        style="cursor: pointer"
                        >{{ $t("label.Rejected") }}
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content bg-white rounded p-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pending_orders"
                      role="tabpanel"
                      aria-labelledby="pending_orders-tab"
                    >
                      <div class="row mb-3">
                        <div class="col-lg-6 col-md-4 d-md-flex mb-md-0 mb-2">
                          <div class="input-group page-cont-search border col-md-6">
                            <span class="input-group-text"
                              ><i class="bx bx-search"></i
                            ></span>
                            <input
                              type="text"
                              class="form-control"
                              name=""
                              v-on:keyup.enter="getOrderList(1)"
                              :placeholder="$t('label.search here')"
                              v-model="searchObjs.keyword"
                            />
                          </div>
                        </div>
                        <!-- <div class="col-md-3 mb-md-0 mb-2">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> From</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="input-group flex-nowrap">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="bx bx-calendar mr-1"></i> To</span
                              >
                            </div>
                            <input type="text" class="form-control" />
                          </div>
                        </div> -->
                      </div>
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ $t("label.Sr_No") }}</th>
                            <th>{{ $t("label.Order ID") }}</th>
                            <th>{{ $t("label.Date & Time") }}</th>
                            <th>{{ $t("label.Preferred Date") }}</th>
                            <th>{{ $t("label.Customer Name") }}</th>

                            <th>{{ $t("label.Mobile Number") }}</th>
                            <th>{{ $t("label.Email") }}</th>
                            <th>{{ $t("label.Location") }}</th>
                            <th>{{ $t("label.Price") }}</th>
                            <th>{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in OrderList" :key="index">
                            <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                            <td>{{ data.shipping.order_id }}</td>
                            <td>{{ data.created_at }}</td>
                            <td>
                              <div>{{ data.preferred_date }}</div>
                          <span v-if="data.preferred_time == '06_AM_TO_10_AM'">{{
                            $t("label.06 AM TO 10 AM")
                          }}</span>
                          <span v-if="data.preferred_time == '10_AM_TO_02_PM'">{{
                            $t("label.10 AM TO 02 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '02_PM_TO_06_PM'">{{
                            $t("label.02 PM TO 06 PM")
                          }}</span>
                          <span v-if="data.preferred_time == '06_PM_TO_10_PM'">{{
                            $t("label.06 PM TO 10 PM")
                          }}</span>
                            </td>
                            <td>{{ data.name }}</td>

                            <td>{{ data.phone_number }}</td>
                            <td>{{ data.email }}</td>
                            <td>{{ data.shipping.address }}</td>
                            <td>{{ data.net_amount }}</td>
                            <td>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                                v-on:click="
                                  $router.push(
                                    '/service-order-detail/' + data.shipping.order_id
                                  )
                                "
                              >
                                <i class="bx bx-show"></i>
                              </button>
                              <button
                                v-show="currentTab2 == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-success"
                                v-on:click="showModal(data.shipping.order_id, 'ACCEPTED')"
                              >
                                <i class="bx bxs-check-circle"></i>
                              </button>
                              <button
                                v-show="currentTab2 == 'PENDING'"
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                v-on:click="showModal(data.shipping.order_id, 'REJECTED')"
                              >
                                <i class="bx bxs-x-circle"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-lg-12 mt-3">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>

              <ErrorComponent @retry="route(1)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <TextModal ref="textModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">{{ $t("label.Reason") }}</h5>
        </div>
        <Form @submit="updateOrderStatus('REJECTED')">
          <div class="modal-body">
            <div class="floating-form-group">
              <label class="font-medium">{{ $t("label.Reason") }}</label>
              <Field
                id="reason"
                name="reason"
                v-model="reason"
                v-slot="{ field }"
                class="form-control"
                rules="required:reason"
                :validateOnInput="true"
              >
                <textarea type="text-area" class="form-control" v-bind="field" />
              </Field>
            </div>
            <ErrorMessage
              name="reason"
              class="validation-msg mt-2"
              v-show="this.currentTab != 'AREA' || this.currentTab != 'FEES'"
            />
          </div>
          <div class="modal-footer pb-0">
            <button
              class="fill-btn btn-success"
              id="save-button"
              style="width: 18%; border-radius: 5px"
            >
              {{ $t("label.Save") }}
            </button>
            <button
              type="button"
              class="fil-btn btn-danger"
              id="cancel-button"
              style="width: 18%; border-radius: 5px"
              v-on:click="$refs.textModal.closeModal()"
            >
              {{ $t("label.Cancel") }}
            </button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
  <DeleteModal @remove="deletePSRecord" ref="deletePSModal" />
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "../../components/TextModal.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import $ from "jquery";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "ManageProductOrder",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    Pagination,
    ErrorComponent,
    VueGoogleAutocomplete,
    DeleteModal,PhoneCode
  },
  data() {
    return {
      addMore: "0",
      id: this.$route.params.id,
      currentTab: "BASIC",
      file: undefined,
      supplierDetail: {
        address: "",
        operating_area: [],
      },
      operating_area: [],
      areaList: [],
      searchObj: {
        keyword: "",
      },
      fees: {
        shipping_charges: "",
        product_commission_type: "",
        service_commission_type: "",
      },
      checkValue: 0,
      offset: 0,
      earnings: {},
      currentTab2: "PENDING",
      currentTab3: "NEW_VISIT",
      OrderList: [],
      searchObjs: {
        keyword: "",
        order_type: "",
        status: "PENDING_AT_SUPPLIER",
      },
      currentPage: 1,
      loader: true,
      itemsPerPage: 10,
      orderId: "",
      reason: "",
      address: "",
      address1: "",

      area: "",
      latitude: "",
      longitude: "",
      productList: [],
      serviceList: [],
    };
  },
  mounted() {
    this.searchObjs.order_type = this.currentTab;
    this.getSupplierDetail();
  },
  methods: {
    showAddMore() {
      this.addMore = "1";
    },
    addToOperatingAreaList() {
      if (!this.area || this.area.length <= 0 || !this.latitude || !this.longitude) {
        this.$notify({
          type: "error",
          text: "Please enter operating area",
        });
        return;
      }

      this.$api
        .postAPI({
          _action: "supplier/" + this.id + "/operating/area",
          _body: {
            area: this.area,
            latitude: this.latitude,
            longitude: this.longitude,
          },
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.area = "";
            this.latitude = "";
            this.longitude = "";
            this.addMore = "0";
            this.getSupplierDetail();
          }
        })
        .catch(() => {});
    },
    getWorkAddressData: function (addressData, placeResultData) {
      console.log(addressData);
      console.log(placeResultData);
      this.supplierDetail.address = placeResultData.formatted_address;
      this.supplierDetail.latitude = placeResultData.latitude;
      this.supplierDetail.longitude = placeResultData.longitude;

      console.log("address list updated", this.supplierDetail);
    },
    getOperatingWorkAddressData: function (addressData, placeResultData) {
      this.area = placeResultData.formatted_address;
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
    },
    changeTab(tab) {
      this.currentTab = tab;
      // if (tab == "BASIC") {
      //   this.getSupplierDetail(0);
      // } else
      if (tab == "AREA") {
        this.getSupplierDetail(0);
      } else if (tab == "FEES") {
        this.getSupplierDetail(0);
      } else if (tab == "EARNINGS") {
        this.searchObj.status = "EARNINGS";
        this.getEarnings(0);
      } else if (tab == "PRODUCT") {
        this.searchObj.status = "PRODUCT";
        this.searchObjs.order_type = "PRODUCT";
        this.changeTab2("PENDING");
        this.getOrderList(1);
      } else if (tab == "SERVICE") {
        this.searchObj.status = "SERVICE";
        this.searchObjs.order_type = "SERVICE";
        this.changeTab2("PENDING");
        this.getOrderList(1);
      } else if (tab == "PRODUCTS") {
        this.searchObj.status = "PRODUCTS";
        this.searchObjs.order_type = "PRODUCTS";
        this.changeTab2("PENDING");
        this.getProductList(1);
      } else if (tab == "SERVICES") {
        this.searchObj.status = "SERVICES";
        this.searchObjs.order_type = "SERVICES";
        this.changeTab2("PENDING");
        this.getServiceList(1);
      }
    },
    changeTab2(tab) {
      this.currentTab2 = tab;
      if (tab == "PENDING") {
        this.searchObjs.status = "PENDING_AT_SUPPLIER";
        this.getOrderList(1);
      } else if (tab == "ACCEPTED") {
        this.searchObjs.status = "ACCEPTED";
        this.getOrderList(1);
      } else if (tab == "CANCELLED") {
        this.searchObjs.status = "CANCELLED";
        this.getOrderList(1);
      } else if (tab == "REJECTED") {
        this.searchObjs.status = "REJECTED";
        this.getOrderList(1);
      } else if (tab == "COMPLETED") {
        this.searchObjs.status = "COMPLETED";
        this.getOrderList(1);
      }
    },
    changeTab3(tab) {
      this.currentTab3 = tab;
      if (tab == "NEW_VISIT") {
        this.searchObjs.status = "PENDING_AT_SUPPLIER";
        this.searchObjs.prev_parent_id = null;
        // this.searchObj.customer_id = this.id;
        this.searchObjs.order_type = "SERVICE";
        this.getOrderList(1);
      } else if (tab == "OUTGOING") {
        this.searchObjs.status = "ACCEPTED";
        this.searchObjs.prev_parent_id = null;
        // this.searchObj.customer_id = this.id;
        this.searchObjs.order_type = "SERVICE";
        this.getOrderList(1);
      } else if (tab == "VISIT_COMPLETE") {
        this.searchObjs.status = "COMPLETED";
        this.searchObjs.prev_parent_id = null;
        // this.searchObj.customer_id = this.id;
        this.searchObjs.order_type = "SERVICE";
        this.getOrderList(1);
      } else if (tab == "PENDING_NEW_WORK") {
        this.searchObjs.status = "PENDING";
        // this.searchObj.prev_parent_id = null;
        // this.searchObj.customer_id = this.id;
        this.searchObjs.order_type = "SERVICE";
        this.getOrderList(1);
      } else if (tab == "NEW_WORK_UNDER_PROGRESS") {
        // this.searchObj.prev_parent_id = null;
        // this.searchObj.customer_id = this.id;
        this.searchObjs.status = "ACCEPTED";
        this.searchObjs.order_type = "SERVICE";
        this.getOrderList(1);
      } else if (tab == "NEW_WORK_COMPLETE") {
        // this.searchObj.prev_parent_id = null;
        // this.searchObj.customer_id = this.id;
        this.searchObjs.status = "COMPLETED";
        this.searchObjs.order_type = "SERVICE";
        this.getOrderList(1);
      } else if (tab == "REJECTED") {
        this.searchObjs.status = "REJECTED";
        // this.searchObj.customer_id = this.id;
        this.searchObjs.order_type = "SERVICE";
        this.getOrderList(1);
      }
    },
    getAddressData: function (addressData) {
      this.detail.address = addressData.locality;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
    },
    showArea(data) {
      this.$store.commit("setArea", data);
      this.$router.push("/supplier/area");
    },
    pageChange(offset) {
      this.route(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.route(0);
    },
    getSupplierDetail() {
      this.$api
        .getAPI({
          _action: "supplier/" + this.id,
          // _body: this.searchObj,
        })
        .then((res) => {
          this.supplierDetail = res.info;
          this.areaList = res.info.operating_areas;
          this.operating_area = res.info.operating_areas;
          if (this.operating_area.length > 0) {
            this.addMore = "0";
          } else {
            this.addMore = "1";
          }
          this.fees = res.info.setting;
          $("#address1").val(this.supplierDetail.address);
          this.$refs.errorComponent.updateLoader(false);
        })
        .catch(() => {
          // this.loader = false;
          // this.$refs.errorComponent.setData(0, undefined, "");
          // this.$refs.errorComponent.updateLoader(false);
        });
    },
    readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#img_hoder_1").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    route(offset) {
      if (this.currentTab == "EARNINGS") {
        this.getEarnings(offset);
      } else if (this.currentTab == "PRODUCT" || this.currentTab == "SERVICE") {
        this.getOrderList(offset);
      }
    },

    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "operating/area/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteModal.closeModal();
          this.changeTab("AREA");
        })
        .catch(() => {});
    },
    showAddressPopup(id) {
      this.operating_area.splice(id, 1);
    },
    update() {
      if (!(this.operating_area.length > 0)) {
        this.$notify({
          type: "error",
          text: "Please select At-least one  operating area",
        });
      }
      this.supplierDetail.operating_area = this.operating_area;

      this.$api
        .putAPI({
          _action: "supplier/" + this.id,
          _body: this.supplierDetail,
        })
        .then((res) => {
          this.uploadImage();
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.push("/manage-supplier");
          }
        })
        .catch(() => {});
    },

    updateSupplierSetting() {
      this.$api
        .putAPI({
          _action: "supplier/setting/" + this.id,
          _body: this.fees,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.changeTab("FEES");
          }
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "supplier/" + this.id + "/image",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.supplierDetail.avatar = res.path;
          //this.$store.commit("setUser", this.detail);
          this.$router.push("/manage-supplier");
        });
    },
    getEarnings(offset) {
      this.searchObj.status = "ALL";
      this.searchObj.offset = offset;
      this.$refs.errorComponent.updateLoader(true);
      this.$api
        .getAPI({
          _action: "withdraw-requests/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.earnings = res.list;
          // this.availBalance = res.available_balance[0].total_balance;

          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].created_at).format(
              "DD/MM/YYYY hh:mm a"
            );
          }
          this.request = res.list;
          this.offset = res.next_offset;

          if (this.offset >= 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              this.$t("label.Earnings Not Available")
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },
    showModal(id, status) {
      this.orderId = id;
      if (status == "REJECTED") {
        this.$refs.textModal.showModal();
      } else {
        this.updateOrderStatus(status);
      }
    },
    getOrderList(offset, order_by, sort_by) {
      this.loader = true;
      // this.$refs.errorComponent.updateLoader(true);
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (order_by) {
        this.searchObjs.order_by = order_by;
      }
      if (sort_by) {
        this.searchObjs.sort_by = sort_by;
      }
      this.searchObj.offset = offset - 1;
      this.currentPage = offset;
      this.OrderList = [];
      // this.searchObjs.offset = offset;
      this.$api
        .getAPI({
          _action: "orders/" + this.id,
          _body: this.searchObjs,
        })
        .then((res) => {
          this.loader = false;
          console.log("object", res.list);
          for (var k in res.list) {
            res.list[k].created_at = moment(res.list[k].created_at).format(
              "DD/MM/YYYY HH:mm a"
            );
            res.list[k].preferred_date = moment(res.list[k].preferred_date).format(
              "DD/MM/YYYY"
            );
            //  console.log("datesss",res.list[k].created_at);
            this.OrderList.push(res.list[k]);
          }
          this.offset = res.next_offset;

          if (this.offset >= 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            var message =
              this.$helperService.camelize(this.searchObjs.order_type) +
              " Not Available ";
            this.$refs.errorComponent.setData(res, message);
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },

    getServiceList(offset, order_by, sort_by) {
      this.loader = true;
      // this.$refs.errorComponent.updateLoader(true);
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (order_by) {
        this.searchObjs.order_by = order_by;
      }
      if (sort_by) {
        this.searchObjs.sort_by = sort_by;
      }
      this.searchObj.offset = offset - 1;
      this.currentPage = offset;
      this.OrderList = [];
      // this.searchObjs.offset = offset;
      this.searchObjs.supplier_id = this.id;
      this.searchObjs.status = "";
      this.serviceList = [];
      this.$api
        .getAPI({
          _action: "services",
          _body: this.searchObjs,
        })
        .then((res) => {
          this.loader = false;
          console.log("object", res.list);
          for (var k in res.list) {
            res.list[k].created_at = moment(res.list[k].created_at).format(
              "DD/MM/YYYY HH:mm a"
            );
            res.list[k].preferred_date = moment(res.list[k].preferred_date).format(
              "DD/MM/YYYY"
            );
            this.serviceList.push(res.list[k]);
          }
          this.offset = res.next_offset;

          if (this.offset >= 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent && res.count == 0) {
            var message =
              this.$helperService.camelize(this.searchObjs.order_type) +
              " Not Available ";
            this.$refs.errorComponent.setData(res, message);
          } else {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },

    getProductList(offset, order_by, sort_by) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (order_by) {
        this.searchObjs.order_by = order_by;
      }
      if (sort_by) {
        this.searchObjs.sort_by = sort_by;
      }
      this.searchObj.offset = offset - 1;
      this.currentPage = offset;
      this.searchObjs.supplier_id = this.id;
      this.searchObjs.status = "";
      this.productList = [];
      this.$api
        .getAPI({
          _action: "products",
          _body: this.searchObjs,
        })
        .then((res) => {
          this.loader = false;
          console.log("object", res.list);
          for (var k in res.list) {
            res.list[k].created_at = moment(res.list[k].created_at).format(
              "DD/MM/YYYY HH:mm a"
            );
            res.list[k].preferred_date = moment(res.list[k].preferred_date).format(
              "DD/MM/YYYY"
            );
            //  console.log("datesss",res.list[k].created_at);
            this.productList.push(res.list[k]);
          }
          this.offset = res.next_offset;

          if (this.offset >= 0) {
            if (this.$refs.listPagination) {
              if (res.total_count != null && res.total_count > 0) {
                this.$refs.listPagination.setTotalCount(res.total_count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent && res.total_count == 0) {
            var message =
              this.$helperService.camelize(this.searchObjs.order_type) +
              " Not Available ";
            this.$refs.errorComponent.setData(res, message);
          } else {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateLoader(false);
        });
    },

    updateOrderStatus(status) {
      var obj = {};
      if (status == "REJECTED") {
        obj.reason = this.reason;
      }

      obj.status = status;
      this.$api
        .putAPI({
          _action: "order/status/" + this.orderId,
          _body: obj,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.textModal.closeModal();
            this.getOrderList(0);
          }
        })
        .catch(() => {});
    },

    showDeletePopup(id, supplier_id) {
      this.$refs.deletePSModal.showModal(
        this.$t("label.Delete"),
        this.$t("label.Are you sure you want to delete"),
        {
          id: id,
          supplier_id: supplier_id,
        }
      );
    },
    deletePSRecord(obj) {
      var url = "";
      if (this.currentTab == "PRODUCTS") {
        url = "supplier/" + obj.supplier_id + "/product/" + obj.id;
      } else {
        url = "service" + "/" + obj.id;
      }
      this.$api
        .deleteAPI({
          _action: url,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deletePSModal.closeModal();
          this.getProductList(0);
          this.getServiceList(1);
        })
        .catch(() => {});
    },
  },
};
</script>

