<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper no-right-sidebar">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row p-3 align-items-center text-dark">
              <div class="col-md-6 col-5">
                <h2 class="page-title mb-md-0">
                  <a
                    href="javascript:void(0)"
                    v-on:click="$router.go(-1)"
                    class="back-arrow"
                  >
                    <i class="bx bx-arrow-back"></i
                  ></a>
                  {{ id ? $t("label.Edit") : $t("label.Add") }} {{ $t("label.Admins") }}
                </h2>
              </div>
            </div>
            <div
              class="card card-body content-card mb-4 rounded-l border-0 shadow-sm"
              style="width: 120%"
            >
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-5 mb-4 text-center">
                          <div class="mb-3">
                            <img
                              :src="
                                detail.avatar && detail.avatar != null
                                  ? detail.avatar
                                  : '/images/profile.png'
                              "
                              class="rounded-circle border border-light"
                              style="border-width: 3px !important"
                              alt="image thumb"
                              id="preview"
                              width="130"
                              height="130"
                            />
                          </div>
                          <button
                            type="button"
                            class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize"
                            for="pickFile"
                          >
                            {{ $t("label.Change photo") }}
                            <input
                              type="file"
                              class="btn upload-img"
                              id="pickFile"
                              @change="selectedFile($event)"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center mb-3">
                        <div class="col-md-10">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  v-model="detail.name"
                                  class="form-control filled"
                                  rules="required:name"
                                  :validateOnInput="true"
                                />
                                <label for="fname">{{ $t("label.Name") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="detail.email"
                                  class="form-control filled"
                                  rules="required:email"
                                  :validateOnInput="true"
                                />
                                <label for="email">{{ $t("label.Email") }}</label>
                                <span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="phone">{{ $t("label.Phone") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="detail.phone_number"
                                  class="form-control filled"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                  ><PhoneCode
                                    id="mobile"
                                    placeholder="Mobile Number"
                                    v-model:countryCode="detail.country_code"
                                    v-model="detail.phone_number"
                                    v-model:dialCode="detail.dial_code"
                                    v-bind="field"
                                  />
                                </Field>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>

                            <div class="col-md-12" v-if="!this.id">
                              <div class="floating-form-group">
                                <Field
                                  :type="passwordFieldType"
                                  id="password"
                                  name="password"
                                  v-model="detail.password"
                                  class="form-control filled"
                                  rules="required:password"
                                  :validateOnInput="true"
                                />
                                <label for="password">{{ $t("label.Password") }}</label>
                                <i class="bx bx-lock icon"></i>
                                <span
                                  class="input-group-text bg-white"
                                  id="basic-addon2"
                                  style="border-radius: 0px 8px 8px 0px"
                                >
                                  <a class="psw-icon" v-on:click="switchVisibility"
                                    ><img
                                      :src="image"
                                      width="20"
                                      style="margin-bottom: 10px" /></a
                                ></span>
                              </div>
                              <ErrorMessage name="password" class="validation-msg" />
                            </div>
                            <div class="col-md-12" v-if="!this.id">
                              <div class="floating-form-group">
                                <Field
                                  :type="newPasswordFieldType"
                                  id="confirm_password"
                                  name="confirm_password"
                                  v-model="detail.password_confirm"
                                  class="form-control filled"
                                  rules="required:confirm password|confirmed:@password"
                                  :validateOnInput="true"
                                />
                                <label for="password">{{
                                  $t("label.Confirm Password")
                                }}</label>
                                <i class="bx bx-lock icon"></i>
                                <span
                                  class="input-group-text bg-white"
                                  id="basic-addon2"
                                  style="border-radius: 0px 8px 8px 0px"
                                >
                                  <a class="psw-icon" v-on:click="switchVisibilityNew"
                                    ><img
                                      :src="imageNew"
                                      width="20"
                                      style="margin-bottom: 10px" /></a
                                ></span>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label for="role">{{ $t("label.Role") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  as="select"
                                  class="custom-select"
                                  style="height: 50px"
                                  v-model="detail.role_id"
                                  name="role"
                                  rules="required:role"
                                >
                                  <option value="" v-if="!this.id">
                                    {{ $t("label.Select") }}
                                  </option>
                                  <option
                                    v-for="(data, index) in roleList"
                                    :key="index"
                                    :value="data.id"
                                  >
                                    {{ data.role }}
                                  </option>
                                </Field>
                              </div>
                              <ErrorMessage name="role" class="validation-msg mt-3" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row col-11 justify-content-end mt-3 cursor-pointer"
                        v-if="this.id"
                        v-on:click="$router.push('/admin-password/' + this.id)"
                      >
                        <a>{{ $t("label.Change Password") }}</a>
                      </div>
                      <div class="text-center mt-3">
                        <button
                          type="button"
                          class="btn btn-brand-1 mb-3 mr-2 px-5"
                          v-on:click="$router.go(-1)"
                        >
                          {{ $t("label.Cancel") }}
                        </button>
                        <button class="btn btn-brand-1 mb-3 px-5" id="save-button">
                          {{ $t("label.Save") }}
                        </button>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "EditAdmin",
  components: {
    Form,
    Field,
    ErrorMessage,
    PhoneCode,
  },
  data() {
    return {
      id: this.$route.params.id,
      file: undefined,
      detail: {
        dial_code: "+966",
        country_code: "sa",
        name: "",
        email: "",
      },
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      roleName: "",
      roleId: "",
      roleList: [],
      role: {},
    };
  },
  mounted() {
    // alert("hello");
    if (this.id) {
      this.getAdminDetail();
    }
    this.getRoleList();
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    getAdminDetail() {
      this.$api
        .getAPI({
          _action: "user/" + this.id,
        })
        .then((res) => {
          if (res.info.role) {
            res.info.role_id = res.info.role.role_id;
          }

          this.detail = res.info;
          this.roleName = res.info.role.role_name;
          this.roleId = res.info.role.role_id;
          console.log("first", this.roleName);
          console.log("id", this.roleId);
        })
        .catch(() => {});
    },
    getRoleList() {
      this.$api
        .getAPI({
          _action: "roles",
        })
        .then((res) => {
          this.roleList = res.list;
          // console.log("first", this.roleList);
        })
        .catch(() => {});
    },
    update() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "user/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.uploadImage(this.id);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        console.log("detail", this.detail);
        this.$api
          .postAPI({
            _action: "user",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.uploadImage(res.id);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
      }
      this.$api
        .uploadImageAPI({
          _action: "user/" + this.id + "/image",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.detail.avatar = res.path;
          this.$store.commit("setUser", this.detail);
          this.$router.push("/manage-admins");
        });
    },
  },
};
</script>

