<template>
  <div>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center align-items-center min-vh-100">
            <div class="col-lg-6 col-md-9">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="card px-lg-5 px-0 py-3">
                    <div class="card-body">
                      <div class="text-center">
                        <img
                          src="images/gardening_new_log.jpg"
                          width="180"
                          height="37"
                          class="img-fluid mb-5 mx-auto"
                        />
                        <p class="font-medium font-18">Admin</p>
                        <p class="font-medium font-18">Sign In</p>
                      </div>

                      <form class="form-style floating-form">
                        <Form @submit="login">
                          <div class="floating-form-group mb-4 with-icon">
                            <Field
                              type="email"
                              id="email"
                              class="form-control"
                              v-model="details.email"
                              name="Username"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <label for="email">Email</label>
                            <i class="bx bx-envelope icon"></i>
                          </div>
                          <ErrorMessage name="Username" class="validation-msg" />
                          <div class="floating-form-group mb-3 with-icon">
                            <Field
                              :type="passwordFieldType"
                              id="password"
                              class="form-control border-end-0"
                              v-model="details.password"
                              name="Password"
                              rules="required:password"
                              :validateOnInput="true"
                            />
                            <label for="password">Password</label>
                            <i class="bx bx-lock icon"></i>
                            <span
                              class="input-group-text bg-white"
                              id="basic-addon2"
                              style="border-radius: 0px 8px 8px 0px"
                              ><a v-on:click="switchVisibility" class="psw-icon"
                                ><img :src="image" width="20" /></a
                            ></span>
                          </div>
                          <ErrorMessage name="Password" class="validation-msg" />
                          <!-- <div class="form-check pl-0 mb-3 overflow-auto pr-3">
                            <a
                              class="text-dark float-right"
                              href="/forgot-password"
                              >Forgot Password?</a
                            >
                          </div> -->
                          <button
                            id="login-button"
                            style="cursor: pointer"
                            class="btn btn-brand-1 btn-block"
                          >
                            Sign in
                          </button>
                        </Form>
                      </form>
                    </div>
                    <!-- <div class="card-footer text-center bg-white border-0">
                      <div class="links">
                        Don’t have an account?
                        <a
                          href="signup"
                          class="ml-2 text-primary font-medium"
                        >
                          Sign Up</a
                        >
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
export default {
  name: "LoginPage",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      passwordFieldType: "password",
      image: "/images/show.png",
      details: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    localStorage.clear();
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    login() {
      this.$api
        .postAPI({
          _action: "login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          // console.log("token",res.access_token)
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);

            this.getMe();
          }
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res) {
            this.$store.commit("setUser", res.info);
            this.$router.push("/dashboard");
          } else {
            localStorage.removeItem("access_token");
            return;
          }
          // localStorage.setItem("userId", res.info.id);
          // localStorage.setItem("supplier_id", res.info.company.supplier_id);
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
  },
};
</script>

