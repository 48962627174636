import moment from "moment";
import WebService from "./WebService";
import Store from "./StoreService";
class HelperService {
  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  getFormatedDate(d) {
    return moment(d).format("MMM DD, YYYY");
  }

  getFormatedTime(t) {
    return moment.utc(t).local().format("hh:mm A");
  }

  getFormatedDateAndTime(dt) {
    return moment.utc(dt).local().format("DD/MM/YYYY hh:mm A");
    // return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  sendFormatedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  getName(name) {
    var value = "";
    if (name) {
      if (name.length > 19) {
        value = name.substring(0, 19) + "...";
      } else {
        value = name;
      }
    }
    return value;
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  getTitleCase(value) {
    if (!value) {
      return "";
    } else {
      var str = "";
      var arr = value.split("_");
      for (var i in arr) {
        if (i == 0) {
          str =
            arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
        } else {
          str +=
            "" +
            arr[i].charAt(0).toUpperCase() +
            arr[i].substring(1).toLowerCase();
        }
      }
      return str;
    }
  }
  camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, "");
  }
  getSummary() {
    WebService.getAPI({
      _action: "summary",
    })
      .then((res) => {
        Store.commit("setSummary", res);
      })
      .catch(() => {});
  }
}

export default new HelperService({});
